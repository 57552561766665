import React from 'react';
import ShipmentsStyled from './Shipments.styled';
import {
	TextField,
	Autocomplete,
	Checkbox,
	CheckBoxOutlineBlankIcon,
	CheckBoxIcon,
	Box,
	Pagination,
	Card,
	CardContent,
	Tab,
	Tabs,
	InputAdornment,
	SearchIcon,
	Button,
	Menu,
	MenuItem
} from '../../components';
import { useGlobals } from '../../context/globals';
import useSWR from 'swr';
import { stringify } from 'query-string';
import { Alert } from '@mui/material';
import { PATCH } from '../../utils';
import { toast } from 'react-toastify';
import InfoCard from './InfoCard';
const STATES = [
	{
		value: 'RFP',
		label: 'شحنات جاهزة للإستلام'
	},
	{
		value: 'PKD',
		label: 'شحنات تم استلامها'
	},
	{
		value: 'RECD',
		label: 'شحنات في المستودع'
	},
	{
		value: 'OFD',
		label: 'شحنات خرجت للتسليم'
	}
];
const ACTIONS = {
	PICKED_UP: {
		label: 'تأكيد استلام الشحنة',
		color: 'secondary',
		icon: '/icons/picked.svg'
	},
	RECEIVED_IN_WAREHOUSE: {
		label: 'تم إستلام الشحنة في المستودع',
		color: 'info',
		icon: '/icons/warehouse.svg'
	},
	OUT_FOR_DELIVERY: {
		label: 'تأكيد خروج الشحنة للتسليم',
		color: 'warning',
		icon: '/icons/ofd.svg'
	},
	DELIVERED: {
		label: 'تأكيد تسليم الشحنة للعميل',
		color: 'success',
		icon: '/icons/delivered.svg'
	}
};
const SEARCH_OPTIONS = [
	{
		key: 'pretty_id',
		placeholder: 'بحث برقم طلب قوافل',
		name: 'رقم الطلب'
	},
	{
		key: 'vendor_name',
		placeholder: 'بحث باسم المورد',
		name: 'اسم المورد'
	},
	{
		key: 'customer_name',
		placeholder: 'بحث باسم العميل',
		name: 'اسم العميل'
	}
];
function Shipments() {
	const { cities } = useGlobals();
	const [active_tab, set_active_tab] = React.useState(0);
	const [page, set_page] = React.useState(1);
	const [search, set_search] = React.useState('');
	const [debouncedInput, setDebouncedInput] = React.useState('');
	const [confirmation_dialog, set_confirmation_dialog] = React.useState({
		isOpen: false,
		trigger: null,
		shipmentId: null
	});
	const [selectedOption, setSelectedOption] = React.useState(SEARCH_OPTIONS[0]);
	const [action_loading, set_action_loading] = React.useState(false);
	const [filters, set_filters] = React.useState({
		city_from_id: null,
		city_to_id: null,
		logistical_state: 'RFP',
		[selectedOption.key]: ''
	});
	const { data: res, mutate: revalidate } = useSWR(
		`/shipments?${stringify(
			{
				page,
				...{
					...filters,
					city_to_id: filters.city_to_id?.id,
					city_from_id: filters.city_from_id?.id
				}
			},
			{
				skipNull: true,
				skipEmptyString: true,
				arrayFormat: 'bracket'
			}
		)}`
	);
	const pages = Math.ceil(res?.pagination?.filtered / res?.pagination?.size);
	const loading = !res?.data && !res?.data?.errors;
	let data = res?.message === 'success' ? res?.data : [];
	const playNotificationSound = () => {
		const audio = new Audio('/notification_sound.wav');
		audio.play();
	};
	const { data: refresh_res } = useSWR(
		'/shipments?logistical_state=RFP&page=1',
		{
			refreshInterval: 60000
		}
	);
	const RFP_data = refresh_res?.message === 'success' ? refresh_res?.data : [];
	const prevDataRef = React.useRef([]);
	const shipmentsWarning = () => {
		playNotificationSound();
		toast.warning('تم أضافة شحنات جاهزة للإستلام');
	};
	React.useEffect(() => {
		if (RFP_data?.length) {
			if (prevDataRef.current?.length) {
				const newItems = RFP_data?.filter(
					(item) =>
						!prevDataRef?.current?.some((prevItem) => prevItem.id === item.id)
				);

				if (newItems?.length > 0) {
					shipmentsWarning();
					const isEqual =
						filters.city_from_id === null &&
						filters.city_to_id === null &&
						filters.logistical_state === 'RFP' &&
						filters[selectedOption.key] === '' &&
						page == 1;
					if (isEqual) {
						data = RFP_data;
					}
				}
			}

			prevDataRef.current = RFP_data;
		}
	}, [RFP_data]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	React.useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedInput(search);
		}, 500);

		return () => {
			clearTimeout(timerId);
		};
	}, [search]);
	React.useEffect(() => {
		set_filters({ ...filters, [selectedOption.key]: debouncedInput });
	}, [debouncedInput]);
	React.useEffect(() => {
		set_filters({ ...filters, logistical_state: STATES[active_tab].value });
		set_page(1);
	}, [active_tab]);
	function getTimeDifference(updatedAt) {
		const updatedDate = new Date(updatedAt);
		const currentDate = new Date();

		const timeDifference = currentDate.getTime() - updatedDate.getTime();
		const secondsDifference = Math.floor(timeDifference / 1000);
		const minutesDifference = Math.floor(secondsDifference / 60);
		const hoursDifference = Math.floor(minutesDifference / 60);
		const daysDifference = Math.floor(hoursDifference / 24);

		let timeAgo = '';

		if (daysDifference > 0) {
			timeAgo += daysDifference === 1 ? 'يوم' : `${daysDifference} يوم`;
			if (hoursDifference % 24 > 0) {
				timeAgo +=
					hoursDifference % 24 === 1
						? ' و ساعة'
						: ` و ${hoursDifference % 24} ساعة`;
			}
		} else if (hoursDifference > 0) {
			timeAgo += hoursDifference === 1 ? 'ساعة' : `${hoursDifference} ساعة`;
			if (minutesDifference % 60 > 0) {
				timeAgo +=
					minutesDifference % 60 === 1
						? ' و دقيقة'
						: ` و ${minutesDifference % 60} دقيقة`;
			}
		} else if (minutesDifference > 0) {
			timeAgo +=
				minutesDifference === 1 ? 'دقيقة' : `${minutesDifference} دقيقة`;
		} else {
			timeAgo = 'الآن';
		}

		return timeAgo;
	}
	const handleCloseDialog = () => {
		set_confirmation_dialog({
			isOpen: false,
			trigger: null,
			shipmentId: null
		});
	};
	const submit = async () => {
		set_action_loading(true);
		const res = await PATCH({
			endpoint: `/shipments/${confirmation_dialog?.shipmentId}`,
			data: { trigger: confirmation_dialog?.trigger }
		});
		set_action_loading(false);
		if (res?.message === 'success') {
			handleCloseDialog();
			toast.success('تم تأكيد طلبك بنجاح');
			revalidate(
				`/shipments?${stringify(
					{
						page,
						...{
							...filters,
							city_to_id: filters.city_to_id?.id,
							city_from_id: filters.city_from_id?.id
						}
					},
					{
						skipNull: true,
						skipEmptyString: true,
						arrayFormat: 'bracket'
					}
				)}`
			);
		} else {
			toast.error(res?.data?.detail);
		}
	};

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOptionSelect = (option) => {
		setSelectedOption(option);
		handleClose();
	};
	return (
		<ShipmentsStyled>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{SEARCH_OPTIONS.map((option) => (
					<MenuItem
						key={option.key}
						onClick={() => handleOptionSelect(option)}
						selected={option.key === selectedOption.key}
					>
						{option.name}
					</MenuItem>
				))}
			</Menu>
			<div className="search">
				<div className="input_wrapper">
					<TextField
						className="search_input"
						placeholder={selectedOption?.placeholder}
						variant="outlined"
						type="search"
						dir="ltr"
						value={search}
						onChange={(e) => set_search(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<span
										onClick={handleMenuClick}
										style={{ cursor: 'pointer', color: 'rgb(184, 107, 53)' }}
									>
										نوع البحث
									</span>
								</InputAdornment>
							)
						}}
					/>
				</div>
			</div>
			<div className="flex__ai-center__fw-wrap select_wrapper">
				<div className="auto_complete_wrapper">
					<Autocomplete
						value={filters.city_from_id}
						options={cities || []}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						onChange={(e, v) => set_filters({ ...filters, city_from_id: v })}
						renderOption={(props, option, { selected }) => {
							return (
								<li {...props}>
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.name}
								</li>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="من مدينة"
								placeholder="ابحث عن مدينة"
							/>
						)}
					/>
				</div>
				<div className="auto_complete_wrapper">
					<Autocomplete
						fullWidth
						value={filters.city_to_id}
						options={cities || []}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						onChange={(e, v) => set_filters({ ...filters, city_to_id: v })}
						renderOption={(props, option, { selected }) => {
							return (
								<li {...props}>
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.name}
								</li>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="الي مدينة"
								placeholder="ابحث عن مدينة"
							/>
						)}
					/>
				</div>
			</div>
			<Card className="card__wrapper">
				<CardContent>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={active_tab}
							onChange={(e, v) => set_active_tab(v)}
							variant="scrollable"
						>
							{STATES.map((state) => (
								<Tab key={state?.value} label={state.label} color="info" />
							))}
						</Tabs>
					</Box>
					{loading ? (
						<div className="flex__ai-center__jc-center">
							<div className="lds-roller">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : (
						<>
							{data?.length ? (
								<div className="table_wrapper">
									<table>
										<thead>
											<tr className="table_header">
												<th>تحديث حالة الشحنة</th>
												<th>رقم الطلب في قوافل</th>
												<th> عدد الصناديق</th>
												<th>من</th>
												<th>إلي </th>
												<th> المورد </th>
												<th> العميل </th>
												<th> حاله الشحنة </th>
												<th> اخر تحديث</th>
											</tr>
										</thead>
										<tbody>
											{data?.map((shipment, index) => (
												<tr key={shipment?.id}>
													<td
														className={`row_divider ${
															index === 0 && 'first_td'
														}`}
													>
														{confirmation_dialog.isOpen &&
														confirmation_dialog.shipmentId === shipment.id ? (
															<div>
																<Alert
																	severity="warning"
																	style={{ border: 'none', borderRadius: 8 }}
																>
																	<p style={{ fontWeight: 600 }}>
																		{ACTIONS[confirmation_dialog.trigger].label}
																	</p>
																	<div
																		className="flex__ai-center__jc-between"
																		style={{ marginTop: 12 }}
																	>
																		<Button
																			className="button_radius"
																			variant="contained"
																			onClick={submit}
																			disabled={action_loading}
																		>
																			تأكيد
																		</Button>
																		<Button
																			className="button_radius"
																			variant="text"
																			onClick={handleCloseDialog}
																			disabled={action_loading}
																		>
																			رجوع
																		</Button>
																	</div>
																</Alert>
															</div>
														) : (
															<div className="flex__ai-center__fd-column__gap-1 actions_wrapper">
																{shipment.allowedActions?.map(
																	(action, index) => (
																		<Button
																			dir="ltr"
																			className="button_radius"
																			key={index}
																			onClick={() =>
																				set_confirmation_dialog({
																					isOpen: true,
																					shipmentId: shipment.id,
																					trigger: action
																				})
																			}
																			color={ACTIONS[action].color}
																			variant="contained"
																			startIcon={
																				<img src={ACTIONS[action].icon} />
																			}
																		>
																			{ACTIONS[action].label}
																		</Button>
																	)
																)}
															</div>
														)}
													</td>
													<td data-label="رقم الطلب في قوافل" dir="ltr">
														{shipment?.prettyId}
													</td>
													<td data-label="عدد الصناديق">
														{shipment?.boxesCount}
													</td>
													<td data-label="من">{shipment?.from?.name}</td>
													<td data-label="إلي">{shipment?.to?.name}</td>
													<td data-label="المورد">
														<InfoCard data={shipment?.vendor} />
													</td>
													<td data-label="العميل">
														<InfoCard data={shipment?.customer} />
													</td>
													<td data-label="حاله الشحنة">
														{shipment?.logisticalState?.state?.ar}
													</td>
													<td data-label="اخر تحديث">
														{getTimeDifference(shipment?.updatedAt)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							) : (
								<div className="flex__jc-center empty_table">
									لا يوجد {STATES[active_tab].label}
								</div>
							)}

							{pages >= 2 && (
								<Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
									<Pagination
										dir="ltr"
										count={pages}
										color="primary"
										shape="rounded"
										page={page}
										onChange={(event, value) => set_page(value)}
									/>
								</Box>
							)}
						</>
					)}
				</CardContent>
			</Card>
		</ShipmentsStyled>
	);
}

export default Shipments;
