// import * as colors from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import PingARRegular from '../fonts/PingAR+LT-Regular.otf';
// DOCS : https://mui.com/customization/theming/
const theme = createTheme({
	palette: {
		primary: {
			light: '#0C0E12',
			main: '#0A0C0F',
			dark: '#08090C',
			contrastText: '#F5F1E9'
		},
		secondary: {
			light: '#c79f54',
			main: '#b86b35',
			dark: '#833f08',
			contrastText: '#fff'
		},
		white: {
			light: '#fff',
			main: '#fff',
			dark: '#fff',
			contrastText: '#444A57'
		}
	},
	typography: {
		fontFamily: 'PingARRegular, Roboto, sans-serif',
		h1: {
			fontSize: '1.8rem',
			fontWeight: 400
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
			  @font-face {
				font-family: 'PingARRegular';
				font-style: normal;
				font-display: swap;
				font-weight: 400;
				src: local('PingARRegular'), url(${PingARRegular}) format('opentype');
			  }
			`
		},
		MuiCard: {
			styleOverrides: {
				// Name of the slot
				root: {
					padding: '1rem 1.2rem'
				}
			}
		}
	}
});

export default theme;
