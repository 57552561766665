
const removeUnusedData = (data) =>{
	for(let prop in data){
		if(data[prop] === null || data[prop] === ''){
			delete data[prop];
		}
	}
	return data;
};

export default removeUnusedData;