import styled from 'styled-components';

const LayoutStyled = styled.div`
	direction: rtl;
	&.render-app-layout {
		main {
			padding: 0px 24px 20px;
			background-color: #f8f8f8;
			height: calc(100vh - 106px);
			overflow-y: auto;
			position: relative;
			flex: 1 1 100%;
		}
		@media (max-width: 768px) {
			main {
				height: auto;
				padding: 0px 10px 20px;
			}
		}
	}
	main {
		padding: 0;
		position: relative;
		flex: 1 1 100%;
	}
`;

export default LayoutStyled;
