import React from 'react';
import HeaderStyled from './Header.styled';
import { Menu, MenuItem } from '../../index';
import { useAuth } from '../../../context/auth';
import { useNavigate } from 'react-router-dom';

function Header() {
	const { user, clearAuth } = useAuth();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutClick = () => {
		clearAuth();
		navigate('/login');
		window.location.reload();
		handleClose();
	};
	return (
		<HeaderStyled>
			<div className="header">
				<div className="logo">
					<img src="/img/logo.svg" alt="Qawafel's logo" className="logo" />
				</div>
				<div className="des">
					<h1>إدارة شحنـات قـوافـل</h1>
				</div>
				<div className="header-icons">
					<div className="user-badge" onClick={handleClick}>
						<div className="avatar">
							{user?.name
								?.split(' ')
								.map((name) => name.charAt(0))
								.join('')
								.toUpperCase()}
						</div>
					</div>
				</div>
			</div>
			<Menu
				id="user-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'.MuiPaper-root': {
						borderRadius: '8px',
						boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
						border: '1px solid rgba(0, 0, 0, 0.12)',
						backgroundColor: '#fff',
						color: '#333',
						padding: '4px 0',
						overflow: 'hidden',
						marginTop: '4px',
						'& .MuiMenu-list': {
							padding: '0'
						},
						'& .MuiMenuItem-root': {
							padding: '10px 20px',
							fontSize: '0.9rem'
						}
					}
				}}
			>
				<MenuItem
					disabled={true}
					style={{
						color: '#007bff',
						fontWeight: 'bold',
						textTransform: 'capitalize'
					}}
					sx={{
						transition: 'background-color 0.3s ease, color 0.3s ease',
						'&:hover': {
							backgroundColor: 'rgba(0, 123, 255, 0.1)',
							color: '#007bff'
						}
					}}
				>
					{user?.name}
				</MenuItem>
				<MenuItem
					onClick={handleLogoutClick}
					sx={{
						transition: 'background-color 0.3s ease, color 0.3s ease',
						'&:hover': {
							backgroundColor: 'rgba(255, 0, 0, 0.1)',
							color: '#ff0000'
						}
					}}
				>
					Logout
				</MenuItem>
			</Menu>
		</HeaderStyled>
	);
}

export default Header;
