import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SWRConfig } from 'swr';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import SWRConfigs from './config/SWR';
import theme from './config/MUI-theme';
import { AuthProvider } from './context/auth';
import { GlobalsProvider } from './context/globals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AuthProvider>
		<SWRConfig value={SWRConfigs}>
			<Router>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<GlobalsProvider>
						<App />
					</GlobalsProvider>
				</ThemeProvider>
			</Router>
		</SWRConfig>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
