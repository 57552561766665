import { create } from 'axios';
import { GET } from '../utils';

const axios = create({
	baseURL: process.env.REACT_APP_BASE_URL
});

const fetcher = (endpoint, ...rest) => {
	return GET({ endpoint, ...rest });
};

export default { axios, fetcher };
