import styled from 'styled-components';

const HeaderStyled = styled.div`
	.header {
		padding: 20px 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		border: 1px solid rgba(0, 0, 0, 0.12);
		.logo {
			img {
				max-width: 100%;
				height: auto;
			}
		}
		.des h1 {
			font-size: 2.5rem;
			letter-spacing: 0.6px;
			font-weight: bold;
			word-spacing: 3px;
			margin: 0;
		}
	}
	.user-badge {
		cursor: pointer;
		.avatar {
			background-color: #f2f4f7;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 6px;
			color: #007bff;
			font-size: 1.2rem;
			letter-spacing: 1px;
			font-weight: 600;
			border: 2px solid rgba(0, 0, 0, 0.12);
		}
	}
	.user-menu-item {
		color: #007bff;
	}
	@media screen and (max-width: 1024px) {
		.header {
			padding: 15px 20px;
			.des h1 {
				font-size: 2rem;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.header {
			flex-direction: column;
			gap: 10px;
			padding: 10px 15px;
			.logo {
				width: 140px;
				img {
					max-width: 100%;
					height: auto;
				}
			}
			.des h1 {
				font-size: 1.5rem;
				text-align: center;
			}
		}
	}
`;

export default HeaderStyled;
