import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth';
import { GET } from '../utils';
const GlobalsContext = createContext();

export const GlobalsProvider = ({ children }) => {
	const { token } = useAuth();
	const [cities, set_cities] = useState([]);

	const fetchCities = async () => {
		let allCities = [];
		let hasMorePages = true;
		let currentPage = 1;
		const pageSize = 200;
		while (hasMorePages) {
			const { data, pagination } = await GET({
				endpoint: `/cities?page=${currentPage}&size=${pageSize}`
			});
			allCities = [...allCities, ...data];
			hasMorePages = pagination?.remainingPages > 0;
			currentPage++;
		}
		set_cities(allCities);
	};
	useEffect(() => {
		if (token && !cities.length) {
			fetchCities();
		}
	}, [token]);

	return (
		<GlobalsContext.Provider value={{ cities }}>
			{children}
		</GlobalsContext.Provider>
	);
};

export const useGlobals = () => {
	return useContext(GlobalsContext);
};
