import styled from 'styled-components';

const ShipmentsStyled = styled.div`
	.lds-roller {
		color: #1c4c5b;
		margin: 50px 0;
	}
	.lds-roller,
	.lds-roller div,
	.lds-roller div:after {
		box-sizing: border-box;
	}
	.lds-roller {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 40px 40px;
	}
	.lds-roller div:after {
		content: ' ';
		display: block;
		position: absolute;
		width: 7.2px;
		height: 7.2px;
		border-radius: 50%;
		background: currentColor;
		margin: -3.6px 0 0 -3.6px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 62.62742px;
		left: 62.62742px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 67.71281px;
		left: 56px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 70.90963px;
		left: 48.28221px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 72px;
		left: 40px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 70.90963px;
		left: 31.71779px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 67.71281px;
		left: 24px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 62.62742px;
		left: 17.37258px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 56px;
		left: 12.28719px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.select_wrapper {
		margin-bottom: 30px;
	}
	.auto_complete_wrapper {
		min-width: 200px;
		background: #fff;
	}
	.button_radius {
		border-radius: 8px;
	}
	.search {
		margin: 40px 0 20px;
		display: flex;
		justify-content: center;
		.input_wrapper {
			width: 700px;
			.search_input {
				width: 100%;
				background-color: #fff;
			}
		}
	}
	.empty_table {
		margin: 50px 0 20px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.6);
	}
	.table_wrapper {
		max-width: 100%;
		border: 2px solid #eee;
		box-shadow: 0px 2px 4px -2px #1018280f;
		box-shadow: 0px 4px 8px -2px #1018281a;
		border-radius: 6px;
		margin-top: 40px;
		.mobile {
			color: #6c6e73;
			font-weight: 500;
			font-size: 12px;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		overflow-x: auto;
		text-overflow: ellipsis;
		tr {
			overflow: hidden;
		}
	}

	th,
	td {
		padding: 8px;
		text-align: center;
		border: 1px solid #ddd;
		font-weight: 500;
		& td:last-child {
			border-bottom: none;
		}
		p {
			display: inline-block;
		}
	}

	tbody tr {
		cursor: pointer;
		&:hover {
			background-color: rgba(0, 0, 0, 0.03);
		}
		&:nth-child(even) {
			background-color: rgba(0, 0, 0, 0.03);
		}
	}

	thead tr {
		color: #475467;
		background-color: #eaecf0;

		th {
			padding: 16px 8px;
			font-weight: 600;
		}
	}

	@media (max-width: 860px) {
		.card__wrapper {
			padding: 0;
		}
		.select_wrapper {
			justify-content: center;
		}
		.table_wrapper {
			max-height: 100%;
		}
		.table_wrapper table,
		.table_wrapper thead,
		.table_wrapper tbody,
		.table_wrapper th,
		.table_wrapper td,
		.table_wrapper tr {
			display: block;
		}

		.table_wrapper thead {
			display: none;
		}
		.table_wrapper .row_divider {
			border-top: 3px solid #000;
		}
		.table_wrapper .row_divider.first_td {
			border-top: 3px none;
		}
		.table_wrapper td:not(:first-child) {
			width: 100%;
			box-sizing: border-box;
			padding-right: 50%;
			position: relative;
			text-align: left;
			padding-left: 10px;
		}
		.table_wrapper td:not(:first-child):before {
			content: attr(data-label);
			position: absolute;
			right: 0;
			width: 50%;
			padding-right: 10px;
			font-weight: bold;
			text-align: right;
		}
	}
`;

export default ShipmentsStyled;
