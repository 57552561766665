import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RequireAuth from '../../components/shared/Layout/RequireAuth';
import Shipments from './Shipments';

export default function ShipmentsModuleRoutes() {
	return (
		<Routes>
			<>
				<Route
					path="/"
					element={
						<>
							<RequireAuth>
								<Helmet>
									<title>Shipments | Provider Dashboard</title>
								</Helmet>

								<Shipments />
							</RequireAuth>
						</>
					}
				/>
			</>
		</Routes>
	);
}
