import styled from 'styled-components';

const LoginStyled = styled.div`
	direction: ltr;
	background-color: #f8f8f8;
	min-height: 100vh;
	padding: 8%;
	display: flex;
	align-items: center;
	justify-content: center;

	.login-card {
		backdrop-filter: blur(6px);
		background-color: rgba(255, 255, 255, 0.74);
		width: 100%;
		max-width: 580px;
		border-radius: 8px;
		box-shadow: 4px 9px 16px 2px rgba(0, 0, 0, 0.08);
		padding: 5rem 4rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		h2 {
			text-align: center;
			font-size: 1.6rem;
		}

		.logo {
			margin: 0 auto 1.5rem;
			width: 60%;
			max-width: 230px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			margin-top: 0.5rem;

			button {
				width: 100%;
				min-height: 3rem;
				margin-top: 0.6rem;
			}
		}
	}

	@media (max-width: 500px) {
		padding: 8px;

		.login-card {
			padding: 3rem 2rem;

			h2 {
				font-size: 1.6rem;
			}

			form {
				gap: 1.2rem;
			}
		}
	}
`;

export default LoginStyled;
