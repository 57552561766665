import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

export default function RequireAuth({ children }) {
	const { token } = useAuth();
	const location = useLocation();

	return token ? (
		children
	) : (
		<Navigate to="/login" replace state={{ path: location.pathname }} />
	);
}
