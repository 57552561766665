import React from 'react';
import { Typography } from '../../components';
function InfoCard({ data }) {
	return (
		<>
			<Typography
				gutterBottom
				variant="h6"
				component="div"
				sx={{ fontWeight: 'medium', color: '#333', fontSize: 16 }}
			>
				{data?.name || 'No Name Provided'}
			</Typography>
			<Typography
				dir="ltr"
				variant="body2"
				color="text.secondary"
				component="div"
				sx={{ fontSize: '0.875rem' }}
			>
				Contact Name: {data?.contactName || 'Not Available'}
			</Typography>

			<Typography
				variant="body2"
				color="text.secondary"
				sx={{ fontSize: '0.875rem' }}
			>
				{data?.contactMobile}
			</Typography>
		</>
	);
}

export default InfoCard;
