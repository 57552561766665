import axios from './axios';

// eslint-disable-next-line no-unused-vars
function localStorageProvider() {
	// When initializing, we restore the data from `localStorage` into a map.
	const map = new Map(
		JSON.parse(localStorage.getItem('rahal-admin-panel-cache-store') || '[]')
	);

	// Before unloading the app, we write back all the data into `localStorage`.
	window.addEventListener('beforeunload', () => {
		const appCache = JSON.stringify(Array.from(map.entries()));
		localStorage.setItem('rahal-admin-panel-cache-store', appCache);
	});

	// We still use the map for write & read for performance.
	return map;
}

const SWRConfigs = {
	fetcher: axios.fetcher,
	// provider: localStorageProvider,
	onErrorRetry: () => {
		return null;
	},
	onError: (error) => {
		console.log(error);
		// TODO : show error message 'خطأ في تحميل البيانات'

		// TODO : redirect user to logout on any 403 status codes
	}

	// ONLY IN DEV WE DISABLE REVALIDATE
	// ...(process?.env.NODE_ENV === 'production' && {
	// 	// re-validate data each 5s
	// 	refreshInterval: 5000
	// })
};

export default SWRConfigs;
