import React from 'react';
import { Layout } from './components';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDayjs';
import GlobalStyle from './globalStyles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
function App() {
	return (
		<div className="app">
			<ToastContainer />
			<GlobalStyle />
			<LocalizationProvider dateAdapter={DateAdapter}>
				<Layout />
			</LocalizationProvider>
		</div>
	);
}

export default App;
