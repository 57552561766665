// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root{
      /* Colors */
    --c-primary: #0A0C0F;
    --c-primary-light: #0A0C0F;
    --c-primary-dark: #0A0C0F;

    --c-gold: #b86b35;
    --c-gold-light: #c79f54;
    --c-gold-dark: #833f08;

    --c-text: rgba(0, 0, 0, 0.87);
    --c-text-subdued: #7d8083;
    --c-text-disabled: #9f9f9f;

    --c-danger: #dc3545;
    --c-warning: #ffc107;
    --c-success: #28a745;

    --c-gray: #f3f3f8;
    --c-gray-light: #f6f6f6;
    --c-gray-dark: #b1b1b1;
  }
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border: 0;
  }
  a {
    text-decoration: none;
    color: var(--c-text);
  }
  ul {
	  list-style-type: none;
  }

  /* colors classes */
  .c-primary {
    color: var(--c-primary);
  }
  .c-primary-light,
  .c-active {
    color: var(--c-primary-light);
  }
  .c-primary-dark {
    color: var(--c-primary-dark);
  }
  .c-gold {
    color: var(--c-gold);
  }
  .c-gold-light {
    color: var(--c-gold-light);
  }
  .c-gold-dark {
    color: var(--c-gold-dark);
  }
  .c-text {
    color: var(--c-text);
  }
  .c-subdued {
    color: var(--c-text-subdued);
  }
  .c-disabled {
    color: var(--c-text-disabled);
  }
  .c-danger {
    color: var(--c-danger);
  }
  .c-warning {
    color: var(--c-warning);
  }
  .c-success {
    color: var(--c-success);
  }
  /* Flexbox */
  *[class*='flex'] {
    display: flex;
    gap: 0.8rem;
  }
  /* gap */
  *[class*='__gap-0'] {
    gap: 0;
  }
  *[class*='__gap-1'] {
    gap: 1rem;
  }
  *[class*='__gap-2'] {
    gap: 2rem;
  }
  /* justify-content */
  *[class*='__jc-start'] {
    justify-content: flex-start;
  }
  *[class*='__jc-end'] {
    justify-content: flex-end;
  }
  *[class*='__jc-center'] {
    justify-content: center;
  }
  *[class*='__jc-between'] {
    justify-content: space-between;
  }
  *[class*='__jc-around'] {
    justify-content: space-around;
  }
  *[class*='__jc-evenly'] {
    justify-content: space-evenly;
  }
  /* align-items */
  *[class*='__ai-start'] {
    align-items: flex-start;
  }
  *[class*='__ai-end'] {
    align-items: flex-end;
  }
  *[class*='__ai-center'] {
    align-items: center;
  }
  /* flex-direction */
  *[class*='__fd-col'] {
    flex-direction: column;
  }
  *[class*='__fd-row'] {
    flex-direction: row;
  }
  /* flex-wrap */
  *[class*='__fw-wrap'] {
    flex-wrap: wrap;
  }
  *[class*='__fw-nowrap'] {
    flex-wrap: nowrap;
  }
`;

export default GlobalStyle;
