import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);

	useEffect(() => {
		const storedToken = localStorage.getItem('provider_dashboard__user_token');
		const storedUser = localStorage.getItem('provider_dashboard__user');
		if (storedToken && storedUser) {
			setToken(storedToken);
			setUser(JSON.parse(storedUser));
		} else {
			clearAuth();
		}
	}, []);

	const set_user = (newUser) => {
		localStorage.setItem('provider_dashboard__user', JSON.stringify(newUser));
		setUser(newUser);
	};

	const set_token = (newToken) => {
		localStorage.setItem('provider_dashboard__user_token', newToken);
		setToken(newToken);
	};

	const clearAuth = () => {
		localStorage.removeItem('provider_dashboard__user_token');
		localStorage.removeItem('provider_dashboard__user');
		setUser(null);
		setToken(null);
	};

	return (
		<AuthContext.Provider
			value={{ user, token, set_user, set_token, clearAuth }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
