import React from 'react';
import Routes from '../../../routes';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import LayoutStyled from './Layout.styled';

const rejected_routes = ['/login'];

const Layout = () => {
	const location = useLocation();
	const [should_render_layout, set_should_render_layout] = React.useState();

	React.useEffect(() => {
		set_should_render_layout(!rejected_routes.includes(location.pathname));
	}, [location]);

	return (
		<LayoutStyled className={should_render_layout ? 'render-app-layout' : ''}>
			{should_render_layout && <Header />}
			<main>
				<Routes />
			</main>
		</LayoutStyled>
	);
};

export default Layout;
