const formatter = new Intl.NumberFormat('en-US', {
	// style: 'currency',
	// currency: 'SAR',

	// (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	minimumFractionDigits: 0
});

export default function formatMoney(amount) {
	if (isNaN(amount)) return '';
	if (amount === 0) return 0;
	return formatter.format(amount);
}
