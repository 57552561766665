import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Login';
import { Helmet } from 'react-helmet';

export default function VendorUsersModuleRoutes() {
	return (
		<Routes>
			<>
				<Route
					path="/login"
					element={
						<>
							<Helmet>
								<title>Login | Provider Dashboard</title>
							</Helmet>
							<Login />
						</>
					}
				/>
			</>
		</Routes>
	);
}
