import { validURL } from '.';
import axios from '../config/axios';
import removeUnusedData from './removeUnusedData';

const HTTP_REQUEST = async ({
	method,
	endpoint,
	realm = 'provider-dashboard',
	data = {},
	auth = true,
	contentType = 'application/json',
	headers = {},
	options = {},
	version = 'v1'
}) => {
	if (auth) {
		const token = window.localStorage.getItem('provider_dashboard__user_token');
		// const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE2Mzg5NjgwMjgsImV4cCI6MTY0MTU2MDAyOCwiYXVkIjoiaHR0cHM6Ly9hZG1pbi5yYWhhbC5xYXdhZmVsLnNhIiwiaXNzIjoicmFoYWwucWF3YWZlbC5zYSIsInN1YiI6IjEiLCJqdGkiOiIxZTBlYTg1OC1lMDM1LTRmOGUtYTY5ZS1mMzhjMWRkOTlhMmUifQ.M7itLuWUmjEoa7Gf7XD9AETp_WKSUGZDey6Uhr51PoA';
		var auth_header = auth && token ? { Authorization: `Bearer ${token}` } : {};
	}
	try {
		const res = await axios
			.axios({
				method,
				headers: {
					...auth_header,
					...headers,
					'Accept-Language': 'en',
					'Content-Type':
						data instanceof FormData
							? 'multipart/form-data'
							: contentType || 'application/json'
				},
				url: validURL(endpoint) ? endpoint : `/${realm}/${version}${endpoint}`,
				data: removeUnusedData(data),
				...options
			})
			.then((res) => {
				return res.data || res;
			});
		return { ...res, message: res.message || 'success' };
	} catch (err) {
		const error_message = err?.response?.data?.message || 'خطاً';
		if (err?.response?.status === 401) {
			if (localStorage.getItem('provider_dashboard__user_token')) {
				localStorage.removeItem('provider_dashboard__user_token');
				window.location.reload();
			}
			return err.response || err;
		} else {
			console.log(error_message);
		}
		return err.response || err;
	}
};

export const GET = ({
	endpoint,
	realm,
	auth,
	contentType,
	headers,
	options,
	version
}) =>
	HTTP_REQUEST({
		method: 'get',
		endpoint,
		realm,
		auth,
		contentType,
		headers,
		options,
		version
	});

export const POST = ({
	endpoint,
	realm,
	data,
	auth,
	contentType,
	headers,
	options,
	version
}) =>
	HTTP_REQUEST({
		method: 'post',
		endpoint,
		realm,
		data,
		auth,
		contentType,
		headers,
		options,
		version
	});

export const PATCH = ({
	endpoint,
	realm,
	data,
	auth,
	contentType,
	headers,
	options,
	version
}) =>
	HTTP_REQUEST({
		method: 'patch',
		endpoint,
		realm,
		data,
		auth,
		contentType,
		headers,
		options,
		version
	});

export const PUT = ({
	endpoint,
	realm,
	data,
	auth,
	contentType,
	headers,
	options,
	version
}) =>
	HTTP_REQUEST({
		method: 'put',
		endpoint,
		realm,
		data,
		auth,
		contentType,
		headers,
		options,
		version
	});

export const DELETE = ({
	endpoint,
	realm,
	data,
	auth,
	contentType,
	headers,
	options,
	version
}) =>
	HTTP_REQUEST({
		method: 'delete',
		endpoint,
		realm,
		data,
		auth,
		contentType,
		headers,
		options,
		version
	});
