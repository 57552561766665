import React from 'react';
import { Helmet } from 'react-helmet';
import LoginModuleRoutes from './views/Login';
import ShipmentsModuleRoutes from './views/Shipments';

const Routes = () => {
	return (
		<>
			<Helmet>
				<title>Provider Dashboard</title>
			</Helmet>
			<LoginModuleRoutes />
			<ShipmentsModuleRoutes />
		</>
	);
};

export default Routes;
